<template>
  <Tabs value="name1" type="card">
    <TabPane label="基本信息" name="name1">
      <Form
        :model="formData"
        :label-colon="true"
        style="margin: 0 10px"
        :label-width="90"
      >
        <FormItem label="标题">
          <span slot="label" class="validate">标题</span>
          <Input v-model="formData.title" style="width: 50%" :maxlength="50"></Input>
        </FormItem>
        <FormItem label="公布时间">
          <span slot="label" class="validate">公布时间</span>
          <DatePicker
            :options="dateOption"
            :transfer="true"
            v-model="formData.time"
            type="datetimerange"
            style="width: 400px"
            placeholder="请选择公布开始时间和结束时间"
          ></DatePicker>
          <Tooltip placement="top" :delay="500">
            <Icon type="md-help-circle" style="margin: 10px"></Icon>
            <div slot="content">
              <p>到了公共开始时间，终端上才可查看到；</p>
              <p>如果后台发布了，没有到开始时间，终端上也查看不到</p>
            </div>
          </Tooltip>
        </FormItem>
        <FormItem label="发布终端">
          <span slot="label" class="validate">发布终端</span>
          <CheckboxGroup v-model="formData.terminal" @on-change="changeTerminal">
            <Checkbox label="0">全部</Checkbox>
            <Checkbox label="1">TV端</Checkbox>
            <Checkbox label="2">PC端</Checkbox>
            <Checkbox label="3">App</Checkbox>
            <Checkbox label="4">小程序</Checkbox>
            <Checkbox label="5">公众号</Checkbox>
          </CheckboxGroup>
        </FormItem>
        <FormItem label="发布范围">
           <span slot="label" class="validate">发布范围</span>
          <Select
            v-model="city"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择市"
            multiple
            filterable
            @on-change="changeCity"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in cityList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="region"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择区"
            :disabled="regionDisabled"
            multiple
            filterable
            @on-change="changeRegion"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in regionList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="stree"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择街道/乡镇"
            :disabled="streeDisabled"
            multiple
            filterable
            @on-change="changeStree"
          >
            <Option
              :value="item.value"
              v-for="(item, index) in streeList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
          <Select
            v-model="project"
            transfer
            style="width: 25%"
            :max-tag-count="3"
            placeholder="请选择社区"
            :disabled="projectDisabled"
            multiple
            filterable
          >
            <Option
              :value="item.value"
              v-for="(item, index) in projectList"
              :key="index"
              >{{ item.label }}</Option
            >
          </Select>
        </FormItem>
      </Form>
    </TabPane>
    <TabPane label="弹窗设置" name="name2">
      <Form
        :model="formData"
        :label-colon="true"
        style="margin: 0 10px"
        :label-width="90"
        :rules="validatorForm"
        ref="modelSetting"
      >
        <FormItem label="说明">
          <Input
            type="textarea"
            v-model.trim="formData.description"
            :maxlength="200"
            :rows="4"
            :show-word-limit="true"
          ></Input>
        </FormItem>
        <FormItem label="弹窗页面" prop="propPageCode">
          <Input v-model.trim="formData.propPageCode" :maxlength="50"></Input>
        </FormItem>
        <FormItem label="弹窗频率" prop="propFrequency">
          <Select v-model="formData.propFrequency" style="width: 200px">
            <Option value="0">首次进入</Option>
            <Option value="1">每天首次</Option>
            <Option value="2">每月首次</Option>
          </Select>
        </FormItem>
        <FormItem label="是否跳转" prop="propSkip">
          <RadioGroup v-model="formData.propSkip" @on-change="changePropSkip">
            <Radio label="0">否</Radio>
            <Radio label="1">是</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="按钮名称"
          prop="buttonName"
          v-if="formData.propSkip == '1'"
        >
          <Input v-model.trim="formData.buttonName" :maxlength="20"></Input>
        </FormItem>
        <FormItem
          label="跳转链接"
          prop="skipLink"
          v-if="formData.propSkip == '1'"
        >
          <Input v-model.trim="formData.skipLink" :maxlength="200"></Input>
        </FormItem>
        <FormItem label="背景图">
          <img
            ref="image"
            :src="formData.photo"
            alt=""
            width="200"
            style="display: block; margin-bottom: 10px"
          />
          <Progress
            v-if="file.showProgress"
            :percent="file.percentage"
            :stroke-width="5"
          ></Progress>
          <Upload
            :before-upload="beforeUpload"
            :format="['jpg', 'jpeg', 'png', 'gif']"
            style="display: inline-block"
            :on-format-error="imageError"
            :on-progress="imageProgress"
            :on-success="(value, file) => imageSuccess(file)"
            :action="action"
            :data="uploadData"
            :on-remove="imageRemove"
            :show-upload-list="false"
          >
            <Button icon="ios-cloud-upload-outline">上传图片</Button>
          </Upload>
        </FormItem>
      </Form>
    </TabPane>
  </Tabs>
</template>

<script>
export default {
  props: ["addChangeIden", "infoId", "menuCodeObj"],
  data() {
    return {
      formData: { dataScopeIdList: [],time:[],terminal:[], buttonName: "前往" },
      dataScopeIdList: [], //发布范围列表
      dateOption: {
        shortcuts: [
          //公布时间配置项
          {
            text: "1天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 1);
              return [start, end];
            },
          },
          {
            text: "7天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 7);
              return [start, end];
            },
          },
          {
            text: "30天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 30);
              return [start, end];
            },
          },
          {
            text: "365天",
            value() {
              const end = new Date();
              const start = new Date();
              end.setTime(start.getTime() + 3600 * 1000 * 24 * 365);
              return [start, end];
            },
          },
        ],
      },
      validatorForm: {
        propPageCode: [{ required: true, message: "请填写页面编码", trigger: "blur" }],
        propFrequency: [
          { required: true, message: "请选择弹窗频率", trigger: "change" },
        ],
        propSkip: [
          { required: true, message: "请选择是否跳转", trigger: "change" },
        ],
        buttonName: [
          { required: true, message: "请填写按钮名称", trigger: "blur" },
        ],
        skipLink: [
          { required: true, message: "请填写跳转链接", trigger: "blur" },
        ],
      },
      // 上传图片
      action: "",
      file: "",
      imageName: "",
      imagePath: "",
      uploadData: {},
      city: [],
      region: [],
      stree: [],
      project: [],
      cityList: [],
      regionList: [],
      streeList: [],
      projectList: [],

      regionDisabled: true,
      streeDisabled: true,
      projectDisabled: true,
      propId: '',

       // 发布终端临时变量
      terminalList: [],
    };
  },
  methods: {
    // 终端
    changeTerminal(arr) {
     if (this.terminalList.length == 6 && arr.indexOf("0") == -1) {
        this.formData.terminal = [];
      } else if (this.terminalList.length == 6 && arr.indexOf("0") > -1) {
        arr.splice(arr.indexOf("0"), 1);
        this.formData.terminal = arr;
      } else if (arr.length == 5 && arr.indexOf("0") == -1) {
        this.formData.terminal = ["0", "1", "2", "3", "4", "5"];
      } else if (arr.indexOf("0") > -1 && arr.length < 6) {
        this.formData.terminal = ["0", "1", "2", "3", "4", "5"];
      }
      this.terminalList = JSON.parse(JSON.stringify(this.formData.terminal));
    },
    // 是否跳转
    changePropSkip(val) {
      if (val == 0) {
        this.formData.buttonName = "";
        this.formData.skipLink = "";
      }
    },
    // 保存
    save() {

      if (!this.formData.title) {
        this.$Message.error({
          background: true,
          content: "请填写标题",
        });
        return;
      }
      if (!this.formData.time[0] || !this.formData.time[1]) {
        this.$Message.error({
          background: true,
          content: "请选择公布时间",
        });
        return;
      }
      if(this.formData.terminal.length == 0) {
        this.$Message.error({
          background: true,
          content: "请选择发布终端",
        });
        return;
      }
      if ([...this.city, ...this.region, ...this.stree, ...this.project].length == 0) {
        this.$Message.error({
          background: true,
          content: "请选择发布范围",
        });
        return;
      }
      if (!this.formData.propPageCode) {
        this.$Message.error({
          background: true,
          content: "请填写弹窗页面",
        });
        return;
      }
      if (!this.formData.propFrequency && this.formData.propFrequency != '0') {
        this.$Message.error({
          background: true,
          content: "请选择弹窗频率",
        });
        return;
      }
      if (!this.formData.propSkip && this.formData.propSkip != '0') {
        this.$Message.error({
          background: true,
          content: "请选择是否跳转",
        });
        return;
      }
      if (this.formData.propSkip == '1' && !this.formData.buttonName) {
        this.$Message.error({
          background: true,
          content: "请填写按钮名称",
        });
        return;
      }
      if (this.formData.propSkip == '1' && !this.formData.skipLink) {
        this.$Message.error({
          background: true,
          content: "请填写跳转链接",
        });
        return;
      }
      this.$refs.modelSetting.validate((status) => {
        if (status) {
          let params = {};
          let url = "";
          if (this.addChangeIden) {
            url = "/datamsg/api/pc/information/prop/create";
          } else {
            url = "/datamsg/api/pc/information/prop/update";
            params = {
              id: this.infoId,
              propId: this.propId
            };
          }
          let terminal = this.formData.terminal.join(",").indexOf('0') != -1 ? '0' :  this.formData.terminal.join(",");
          console.log(url,terminal,params);
          
       
            this.$post(
            url,
            {
              ...params,
              ...this.formData,
              columnCode: this.menuCodeObj.columnCode,
              orgCode: parent.vue.loginInfo.userinfo.orgCode,
              operator: {
                businessType: this.menuCodeObj.businessType,
                functionType: this.menuCodeObj.functionType,
                oemCode: parent.vue.oemInfo.oemCode,
                operatorCustGlobalId:
                  parent.vue.loginInfo.userinfo.custGlobalId,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                terminal: terminal,
              },
              terminal:terminal,
              endTime: this.formData.time[1] ? this.$core.formatDate(new Date(this.formData.time[1]),'yyyy-MM-dd hh:mm:ss') : '',
              source: parent.vue.loginInfo.userinfo.orgName,
              startTime: this.formData.time[0] ? this.$core.formatDate(new Date(this.formData.time[0]),'yyyy-MM-dd hh:mm:ss') : '',
              status: "1",
              recommend: "0",
              sort: "100",
              dataScopeIdList: (() => {
                  if (this.project.length > 0) {
                    return this.project;
                  } else if (this.stree.length > 0) {
                    return this.stree;
                  } else if (this.region.length > 0) {
                    return this.region = typeof this.region == 'string' ? [this.region] : this.region;
                  } else {
                    return typeof this.city == "string"
                      ? this.city.split(",")
                      : this.city;
                  }
                })(),
            },
            { "Content-Type": "application/json" }
          )
            .then((res) => {
              if (res.code == 200) {
                this.$Message.success({
                  background: true,
                  content: "保存成功",
                });
                this.$emit("saveSuccess");
              } else {
                this.$Message.error({
                  background: true,
                  content: res.desc,
                });
              }
            })
            .catch((err) => {
              console.log(err);
              this.$Message.error({
                background: true,
                content: "保存失败",
              });
            });
          
          
        } else {
          this.$Message.error({
            background: true,
            content: "必填项不能为空",
          });
        }
      });
    },
    // 城市选择
    changeCity(code) {
      if (code.length >= 2) {
        this.regionList = [];
        this.streeList = [];
        this.projectList = [];
        this.region = [];
        this.stree = [];
        this.project = [];
        this.regionDisabled = true;
        this.streeDisabled = true;
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.regionDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "regionList", "region");
      }
    },
    // 区选择
    changeRegion(code) {
      if (code.length >= 2) {
        this.streeList = [];
        this.projectList = [];
        this.stree = [];
        this.project = [];
        this.streeDisabled = true;
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.streeDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(arrCode[arrCode.length - 1], "streeList", "stree");
      }
    },
    // 街道选择
    changeStree(code) {
      if (code.length >= 2) {
        this.projectList = [];
        this.project = [];
        this.projectDisabled = true;
      } else if (code.length == 1) {
        this.projectDisabled = false;
        let arrCode = code[code.length - 1].split("-");
        this.getPulishData(
          arrCode[arrCode.length - 1],
          "projectList",
          "project"
        );
      }
    },
    // 获取发布范围列表
    getPulishData(code, list, model) {
      this.$get("/old/api/pc/staff/selectCascadeDataScope", {
        orgCode: code,
        oemCode: parent.vue.oemInfo.oemCode,
        orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
        custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
      }).then((res) => {
        if (res.code == 200) {
          this[list] = res.dataList.map((item) => {
            return {
              value: item.orgPath,
              label: item.orgName,
              orgLevel: item.orgLevel,
            };
          });
          if (res.dataList.length == "1") {
            this[model] = [res.dataList[0].orgPath];
          }
        }
      });
    },
    // 上传之前
    beforeUpload(file) {
      return this.$get(
        "/datamsg/api/common/file/getWebPolicy?bucketName=liefeng"
      ).then((res) => {
        if (res.code == 200) {
          let now = this.$core.randomNumber();
          //防止文件名有.
          let suffixArr = file.name.split(".");
          let suffix = "." + suffixArr[suffixArr.length - 1];
          this.action = window.vue.getProxy()["/oss"].target;
          console.log(this.action);
          this.uploadData = {
            name: now + suffix,
            host: res.data.host,
            key: res.data.key + now + suffix,
            policy: res.data.encodedPolicy,
            OSSAccessKeyId: res.data.accessId,
            success_action_status: "200",
            signature: res.data.postSignature,
          };
          this.imageName = now + suffix;
          this.imagePath = res.data.host + res.data.key + this.imageName;
        }
      });
    },
    //图片上传成功
    imageSuccess(file) {
      if (file.status == "finished") {
        this.formData.photo = this.imagePath;
        this.$Message["success"]({
          background: true,
          content: "图片上传成功！",
        });
      } else {
        this.$Message["error"]({
          background: true,
          content: "图片上传失败！",
        });
      }
    },
    // 图片格式错误
    imageError(file) {
      this.$Notice.warning({
        title: "上传文件格式错误！",
        desc:
          "文件 " +
          file.name +
          " 上传失败, 请上传jpg, jpeg, png或gif格式的图片！",
      });
    },
    // 图片上传进度
    imageProgress(event, file, fileList) {
      this.file = file;
      event.target.onprogress = (event) => {
        let uploadPercent = parseFloat(
          ((event.loaded / event.total) * 100).toFixed(2)
        ); // 保留两位小数，具体根据自己需求做更改
        // 手动设置显示上传进度条 以及上传百分比
        this.file.showProgress = true;
        this.file.percentage = uploadPercent;
      };
    },
    // 图片移除
    imageRemove(file) {
      this.formData.photo = "";
      this.file = {};
    },
  },
  async created() {
    //获取信息类型subType
    this.$get("/voteapi/api/pc/menu/getMenuParam", {
      columnCode: this.$core.getUrlParam("menuId"),
      orgCode: parent.vue.loginInfo.userinfo.orgCode,
    }).then((res) => {
      this.formData.subType = res[0] ? res[0].value : '';
    });
    await this.getPulishData("44", "cityList", "city");

    // 修改回显数据
    if (this.infoId) {
      this.$get("/datamsg/api/pc/information/v2/queryById", {
        infoId: this.infoId,
        operatorCustGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
        orgCode: parent.vue.loginInfo.userinfo.orgCode,
        oemCode: parent.vue.oemInfo.oemCode,
        functionType: this.menuCodeObj.functionType,
        terminal: '2'
      }).then((res) => {
        if (res.code == 200 && res.data) {
          let terminal = res.data.terminal.split(',').indexOf('0') != -1 ? ['0','1','2','3','4','5'] : res.data.terminal.split(',');
          this.formData = {
            title: res.data.title,
            time: [res.data.startTime,res.data.endTime],
            terminal: terminal,
            description: res.data.description,
            buttonName: res.data.prop.buttonName,
            photo: res.data.photo,
            propPageCode: res.data.prop.propPageCode,
            propFrequency: res.data.prop.propFrequency,
            propSkip: res.data.prop.propSkip,
            skipLink: res.data.prop.skipLink
          };
          this.propId = res.data.prop.id;
          if(res.data.dataScopeList.length == 1) {
            res.data.dataScopeList.map(item => {
            let arr = item.split('-');
            switch (arr.length) {
              case 2: 
                this.city = item;
                break;
              case 3:
                this.city = arr[0] + '-' + arr[1];        
                this.region = item;
                break;
              case 4: 
                this.city = arr[0] + '-' + arr[1];
                this.region = arr[0] + '-' + arr[1] + '-' + arr[2];
                this.stree = item;
                break;
              case 5: 
                this.city = arr[0] + '-' + arr[1];
                 this.region = arr[0] + '-' + arr[1] + '-' + arr[2];
                this.stree = arr[0] + '-' + arr[1] + '-' + arr[2] + '-' + arr[3];
                this.project = item;
                break;
            }
          })
          }else if((res.data.dataScopeList.length > 1)) {
              res.data.dataScopeList.map(item => {
            let arr = item.split('-');
            switch (arr.length) {
              case 2: 
                this.city.push(item);
                break;
              case 3:
                this.city.push(arr[0] + '-' + arr[1]);        
                this.region.push(item);
                break;
              case 4: 
                this.city.push(arr[0] + '-' + arr[1]);
                this.region.push(arr[0] + '-' + arr[1] + '-' + arr[2]);
                this.stree.push(item);
                break;
              case 5: 
                this.city.push(arr[0] + '-' + arr[1]);
                this.region.push(arr[0] + '-' + arr[1] + '-' + arr[2]);
                this.stree.push(arr[0] + '-' + arr[1] + '-' + arr[2] + '-' + arr[3]);
                this.project.push(item);
                break;
            }
          })
          this.city = Array.from(new Set(this.city));
          this.region = Array.from(new Set(this.region));
          this.stree = Array.from(new Set(this.stree));
          this.project = Array.from(new Set(this.project));
          }
        
        }
      });
    }
  },
};
</script>
    
<style scoped lang='less'>
/deep/.ivu-tooltip-inner {
  max-width: 100%;
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
</style>